import React from "react"
import Modal, { ModalProps } from "./Modal";
import classes from './InfoModal.module.scss'

interface InfoModalProps {
  message?: string
  child?: React.ReactNode
  isCenter?: boolean
}

// TODO: use tooltip instead
const InfoModal: React.FC<InfoModalProps & ModalProps> = (props) => {
  const {opened, onClose, title, message, child, isCenter} = props
  return <Modal opened={opened} onClose={onClose} title={title}>
    <div className={classes.InfoModal}>
      {child}
      <div className={isCenter ? classes.MessageCenter : classes.Message}>{message}</div>
    </div>
  </Modal>
}

export default InfoModal
