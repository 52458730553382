import React, { PropsWithChildren } from "react"
import { Modal as BModal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export interface ModalProps {
  opened: boolean
  onClose: VoidFunction
  title?: string
  className?: string
  onBackClick?: VoidFunction
}

const Modal: React.FC<ModalProps & PropsWithChildren<any>> = (props) => {

  const {opened, onClose, title, children, className, onBackClick} = props

  return <BModal
    show={opened}
    onHide={onClose}
    className={className}>

    {title && <BModal.Header>
      {onBackClick ?
        <FontAwesomeIcon icon={faArrowLeft} className='close-button' onClick={onBackClick}/> :
        <div className={'back-button-stub'}/>}
      <BModal.Title>{title}</BModal.Title>
      <div className='close-button' onClick={onClose}/>
    </BModal.Header>}

    <BModal.Body>
      {children}
    </BModal.Body>

  </BModal>
}

export default Modal
