import React, {useMemo} from 'react'
import classes from './EventItem.module.scss'
import {Link} from "react-router-dom";
import {Shimmer, Shimmers} from "./Shimmer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faImage, faEye} from "@fortawesome/free-solid-svg-icons";
import {isEmpty, isNotEmpty} from "../util/array_util";
import {Product} from "../models";
import {getAnalytics, logEvent} from "firebase/analytics";
import useCountry from "../hooks/useCountry";

interface ProductItemProps {
  product?: Product
  shimmer?: boolean
  index?: number
}

export const ProductItem: React.FC<ProductItemProps> = ({product, shimmer, index}) => {
  const {country} = useCountry()

  const {
    id,
    name,
    brand,
    price,
    store,
    storeId,
    description,
    photos,
  } = product ?? {}

  const logOpenProduct = () => {
    logEvent(getAnalytics(), 'click_open_product', {
      productId: product?.id,
      productName: product?.name,
      productBrand: product?.brand,
      product: product,
    });
  }

  const priceLocal = useMemo(() => {
    if (country == 'IL') {
      return `₪${price}`
    } else {
      return `€${Math.floor((price ?? 0) / 3.5)}`
    }
  }, [country])

  return <div style={{overflowX: 'hidden'}}>
    {product && <Link to={`/product/${id}`} onClick={logOpenProduct}>
      <div className={classes.EventItem}>
        <div className={classes.ImageContainer}>
          {isNotEmpty(photos) && <img src={(photos ?? [])[0] ?? ''} className={classes.Image}/>}
          {isEmpty(photos) && <div className={classes.ImagePlaceholder}>
            <FontAwesomeIcon icon={faImage} className={classes.Icon}/>
          </div>}
        </div>
        <div className={classes.Info}>
          <div className={classes.Title}>{name}</div>
          {/*<div className={classes.Title}>{brand}</div>*/}
        </div>
        {/*<AppButton*/}
        {/*  className={'m-4'}*/}
        {/*  title={'Details'}*/}
        {/*  icon={faEye}*/}
        {/*/>*/}
      </div>
    </Link>}

    {shimmer && <Shimmers>
      <div className={classes.EventItem} style={{width: '100%'}}>
        <div className={classes.ImageContainer}>
          <Shimmer className={classes.Image} expand height={175}/>
        </div>
        <div className={classes.Info}>

          <div>
            <Shimmer width={70}/>
          </div>
          <div className={'mt-2'}>
            <Shimmer width={170}/>
          </div>
          <div className={'mt-2'}>
            <Shimmer width={110}/>
          </div>
          <div className={'mt-5'}>
            <Shimmer width={140} height={44}/>
          </div>


          <div className={classes.FavoriteButtonContainer}>
            <Shimmer width={24} height={24} borderRadius={12}/>
          </div>

        </div>
      </div>
    </Shimmers>}
  </div>

}
