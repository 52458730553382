import {getStores} from '../services/api/api'
import useSWR from 'swr'

export default function useStores() {
  const {data, error, isLoading} = useSWR('getStores', getStores)
  return {
    stores: data,
    storesLoading: isLoading,
    storesError: error,
  }
}
