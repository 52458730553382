export const setPaid = (paid: boolean): void => {
  localStorage.setItem('paid', paid ? 'true' : 'false')
}

export const getPaid = (): boolean => {
  return localStorage.getItem('paid') == 'true'
}

export const getToken = (): string | null => {
  const token = localStorage.getItem('token')
  if (!token) {
    return null
  }
  return token;
}

export const saveToken = (token: string) => {
  localStorage.setItem('token', token);
}

export const clearToken = () => {
  localStorage.setItem('token', '');
}

export const getUserLocale = (): string | null => {
  return localStorage.getItem('user_locale')
}

export const setUserLocale = (locale: string): void => {
  localStorage.setItem('user_locale', locale)
}
