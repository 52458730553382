import { DateTime } from "luxon";

const numberFormat = Intl.NumberFormat('en')
const numberFormatTwoDigits = Intl.NumberFormat('en', {minimumFractionDigits: 2})

export const moneyFormat = (value: string | number): string => {
  const formattedValue = (value.toString()).replace(/[^\d.-]/g, '')
  if (formattedValue.length == 0) {
    return ''
  }
  return '$' + numberFormat.format(parseFloat(formattedValue))
}

export const moneyFormatTwoDigits = (value: string | number): string => {
  const formattedValue = (value.toString()).replace(/[^\d.-]/g, '')
  if (formattedValue.length == 0) {
    return ''
  }
  return '$' + numberFormatTwoDigits.format(parseFloat(formattedValue))
}

export const moneyToString = (moneyFormattedString: string): string => {
  return moneyFormattedString.replace(/[^\d.-]/g, '')
}
