import {DateTime} from "luxon"

export const formatDate = (date?: Date | string, pattern?: string): string | undefined => {
  if (!date) {
    return undefined
  }

  return DateTime.fromJSDate(typeof date == 'string' ? new Date(date) : date).toFormat(pattern ?? 'yyyy-MM-dd')
}

export const formatNow = (pattern?: string): string | undefined => {
  return DateTime.now().toFormat(pattern ?? 'yyyy-MM-dd')
}

export const startOfDay = (date?: Date): Date => {
  if (!date) {
    return DateTime.now().startOf('day').toJSDate()
  }
  return DateTime.fromJSDate(date).startOf('day').toJSDate()
}

export const endOfDay = (date: Date): Date => {
  return DateTime.fromJSDate(date).endOf('day').toJSDate()
}

export const isToday = (date?: Date): boolean => {
  return !!date && sameDay(date, new Date())
}

export const isTomorrow = (date?: Date): boolean => {
  return !!date && sameDay(date, DateTime.now().plus({days: 1}).toJSDate())
}

export const isYesterday = (date?: Date): boolean => {
  return !!date && sameDay(date, DateTime.now().minus({days: 1}).toJSDate())
}

export const tomorrow = (): Date => {
  return DateTime.now().plus({days: 1}).toJSDate()
}

export const yesterday = (): Date => {
  return DateTime.now().minus({days: 1}).toJSDate()
}

export const sameDay = (d1?: Date, d2?: Date): boolean => {

  if (!d1 || !d2) {
    return false
  }

  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
}

export const sameWeek = (d1: Date, d2: Date): boolean => {
  return DateTime.fromJSDate(d1).weekNumber == DateTime.fromJSDate(d2).weekNumber
}

export const sameMonth = (d1?: Date, d2?: Date): boolean => {
  return !!d1 && !!d2 && d1.getMonth() == d2.getMonth()
}

export const sameYear = (d1: Date, d2: Date): boolean => {
  return d1.getFullYear() == d2.getFullYear()
}

export const startOfWeek = (date?: Date): Date => {
  return DateTime.fromJSDate(date ?? new Date()).startOf('week').toJSDate()
}

export const isStartOfWeek = (date?: Date): boolean => {
  return !!date && sameDay(date, startOfWeek(date))
}

export const isEndOfWeek = (date?: Date): boolean => {
  return !!date && sameDay(date, endOfWeek(date))
}

export const isStartOfThisWeek = (date?: Date): boolean => {
  return !!date && sameDay(date, startOfWeek())
}

export const isStartOfPreviousWeek = (date?: Date): boolean => {
  return !!date && sameDay(date, startOfWeek(DateTime.fromJSDate(date).minus({week: 1}).toJSDate()))
}

export const endOfWeek = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).endOf('week').toJSDate()
}

export const startOfMonth = (date?: Date): Date => {
  return DateTime.fromJSDate(date ?? new Date()).startOf('month').toJSDate()
}

export const endOfMonth = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).endOf('month').toJSDate()
}

export const isStartOfMonth = (date?: Date): boolean => {
  return !!date && sameDay(date, startOfMonth(date))
}

export const isEndOfMonth = (date?: Date): boolean => {
  return !!date && sameDay(date, endOfMonth(date))
}

export const startOfYear = (date?: Date): Date => {
  return DateTime.fromJSDate(date ?? new Date()).startOf('year').toJSDate()
}

export const isStartOfYear = (date?: Date): boolean => {
  return !!date && sameDay(date, startOfYear(date))
}

export const isEndOfYear = (date?: Date): boolean => {
  return !!date && sameDay(date, startOfYear(date))
}

export const endOfYear = (date?: Date): Date => {
  if (!date) {
    return DateTime.now().endOf('year').toJSDate()
  }
  return DateTime.fromJSDate(date).endOf('year').toJSDate()
}

export const inThisWeek = (date?: Date): boolean => {
  console.log(`inThisWeek - date:      ${date}`)
  console.log(`inThisWeek - date week: ${DateTime.fromJSDate(date!).weekNumber}`)
  console.log(`inThisWeek - now week:  ${DateTime.now().weekNumber}`)
  console.log(`inThisWeek - ---`)
  return !!date && DateTime.fromJSDate(date).weekNumber == DateTime.now().weekNumber
}

export const inPreviousWeek = (date?: Date): boolean => {
  return !!date && DateTime.fromJSDate(date).weekNumber == DateTime.now().minus({week: 1}).weekNumber
}

export const inThisMonth = (date?: Date): boolean => {
  return !!date && new Date().getMonth() == date.getMonth()
}

export const inThisYear = (date?: Date): boolean => {
  return !!date && new Date().getFullYear() == date.getFullYear()
}

export const isNextWeek = (dateFrom: Date, dateTo: Date): boolean => {
  return DateTime.fromJSDate(dateTo).weekNumber == DateTime.fromJSDate(dateFrom).plus({week: 1}).weekNumber
}

export const isNextMonth = (dateFrom: Date, dateTo: Date): boolean => {
  return dateTo.getMonth() == DateTime.fromJSDate(dateFrom).plus({month: 1}).month
}

export const isNextYear = (dateFrom: Date, dateTo: Date): boolean => {
  return dateTo.getFullYear() == DateTime.fromJSDate(dateFrom).plus({years: 1}).year
}

export const plusDay = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).plus({days: 1}).toJSDate()
}

export const minusDay = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).minus({days: 1}).toJSDate()
}

export const plusWeek = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).plus({weeks: 1}).toJSDate()
}

export const minusWeek = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).minus({weeks: 1}).toJSDate()
}

export const plusMonth = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).plus({month: 1}).toJSDate()
}

export const minusMonth = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).minus({month: 1}).toJSDate()
}

export const plusYear = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).plus({years: 1}).toJSDate()
}

export const minusYear = (date?: Date): Date | undefined => {
  if (!date) {
    return undefined
  }
  return DateTime.fromJSDate(date).minus({years: 1}).toJSDate()
}

//

export const startOfNextWeek = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).plus({week: 1}).startOf('week').toJSDate()
}

export const startOfPreviousWeek = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).minus({week: 1}).startOf('week').toJSDate()
}

export const startOfNextMonth = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).plus({month: 1}).startOf('month').toJSDate()
}

export const startOfPreviousMonth = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).minus({month: 1}).startOf('month').toJSDate()
}

export const startOfNextYear = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).plus({year: 1}).startOf('year').toJSDate()
}

export const startOfPreviousYear = (date?: Date | null): Date => {
  return DateTime.fromJSDate(date ?? new Date()).minus({year: 1}).startOf('year').toJSDate()
}

export const over21 = (dateOfBirth?: Date): boolean => {
  return !!dateOfBirth && DateTime.fromJSDate(dateOfBirth).plus({years: 21}).diffNow('years').years < 0
}

export const lessThanHourAgo = (time?: string): boolean => {
  return !!time && DateTime.fromJSDate(new Date(time)).plus({hours: 1}).diffNow('hours').hours > 0
}

export const lessThanMinuteAgo = (time?: string): boolean => {
  return !!time && DateTime.fromJSDate(new Date(time)).plus({minutes: 1}).diffNow('minutes').minutes > 0
}

export const minutesAgo = (time?: string): number => {
  if (!time || DateTime.fromISO(time) > DateTime.now()) {
    return -1
  }
  return Math.abs(DateTime.fromISO(time).diffNow('minutes').minutes)
}

export const dateLocal = (date: string | undefined): Date | undefined => {
  return !date ? undefined : DateTime.fromSQL(date).toJSDate()
}

//
const rangeTitle = (dateFrom: Date, dateTo: Date | undefined): string => {
  if (sameMonth(dateFrom, dateTo)) {
    return `${formatDate(dateFrom, 'd')}-${formatDate(dateTo, 'd MMMM')}`
  } else {
    return `${formatDate(dateFrom, 'd MMM')} - ${formatDate(dateTo, 'd MMM')}`
  }
}
