import React, { ReactElement } from 'react'
import classes from './ViewHeader.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Shimmer } from "./Shimmer";
import { Spacer } from "./Spacer";

interface ViewHeaderProps {
  title?: string
  trailingItems?: Array<ReactElement | undefined>
  showBackButton?: boolean
  onBackClick?: VoidFunction
  loading?: boolean
}

export const ViewHeader: React.FC<ViewHeaderProps> = (props) => {

  const {title, trailingItems, showBackButton, onBackClick, loading} = props
  const navigate = useNavigate()

  const onBackClickInner = () => {
    if (onBackClick) {
      onBackClick()
      return
    }
    navigate(-1)
  }

  return <>
    {!loading && <div className={classes.ViewHeader}>

      {showBackButton && <FontAwesomeIcon
        icon={faArrowLeft}
        onClick={onBackClickInner}
        className={classes.BackButton}/>}

      <h1 className={classes.Title}>{title}</h1>

      {trailingItems}
    </div>}

    {loading && <div className={classes.ViewHeader}>
      <Shimmer width={24} height={24} borderRadius={12}/>
      <Spacer width={10}/>
      <Shimmer width={150}/>
      <Spacer/>
      <Shimmer width={24} height={24} borderRadius={12}/>
    </div>}
  </>
}
