import React, { PropsWithChildren, createContext, useContext, useReducer } from 'react'
import {
  actions,
  initialState,
  initialContext,
  reducer,
  Theme,
  AppContext,
  Locale
} from "../store/store";

const Context = createContext<AppContext>(initialContext)

const AppProvider: React.FC<PropsWithChildren<any>> = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    setTheme: (v: Theme) => dispatch({type: actions.SET_THEME, value: v}),
    setLocale: (v: Locale) => dispatch({type: actions.SET_LOCALE, value: v}),
    setSearch: (v: string) => dispatch({type: actions.SET_SEARCH, value: v})
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export function useAppContext() {
  return useContext(Context)
}

export default AppProvider

