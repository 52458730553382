import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Main from './views/main/Main'
import './index.scss'
import AppProvider from "./components/AppProvider";
import {Toaster} from "react-hot-toast";
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from "@mui/x-date-pickers";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import en from './lang/en.json'
import he from './lang/he.json'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcgV3FFXZweiYHBUB1AeMoZQ_1X7HkD00",
  authDomain: "shoes-price-photos.firebaseapp.com",
  projectId: "shoes-price-photos",
  storageBucket: "shoes-price-photos.appspot.com",
  messagingSenderId: "437374233961",
  appId: "1:437374233961:web:7bcaa584ed7a621df6a1f8",
  measurementId: "G-EZHDRBEGF5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      he: {
        translation: he
      },
    },
    // if you're using a language detector, do not define the lng option
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false
    }
  });

export default function App() {
  return <LocalizationProvider dateAdapter={AdapterLuxon}>
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Main/>}/>
        </Routes>
      </BrowserRouter>
      <Toaster/>
    </AppProvider>
  </LocalizationProvider>
}
