import React, { PropsWithChildren } from 'react'
import classes from './Shimmer.module.scss'
import classnames from 'classnames'

interface ShimmerProps {
  width?: number
  height?: number
  expand?: boolean
  className?: string
  borderRadius?: number
}

export const Shimmers = (props: ShimmerProps & PropsWithChildren<any>) => {
  const {
    children,
    width,
    height,
    expand,
    borderRadius,
    className,
  } = props

  const expandValue = expand ? '100%' : undefined
  return <div
    className={classnames(classes.Shimmers, 'placeholder-glow', className)}
    style={{
      width: width ?? expandValue,
      height: height ?? expandValue,
      borderRadius: borderRadius
    }}>
    {children}
  </div>
}

export const Shimmer = ({width, height, expand, className, borderRadius}: ShimmerProps) => {
  const expandValue = expand ? '100%' : undefined
  return <div
    className={classnames(classes.Shimmer, 'placeholder', className)}
    style={{
      width: width ?? expandValue,
      height: height ?? expandValue,
      borderRadius: borderRadius,
    }}
  />
}
