import React from 'react'

interface SpacerProps {
  width?: number
  height?: number
}

export const Spacer: React.FC<SpacerProps> = ({width, height}) => {
  if (width && height) {
    return <div style={{width, height}}/>
  }

  if (height) {
    return <div style={{height, minHeight: height}}/>
  }

  if (width) {
    return <div style={{width, minWidth: width}}/>
  }
  return <div className={'flex-grow-1'}/>
}
