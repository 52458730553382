import classes from './ProductDetailsView.module.scss'
import useProduct from "../../hooks/useProduct";
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faMapLocationDot,
  faImage,
  faChevronLeft,
  faChevronRight,
  faWallet,
  faCheckCircle,
  faLocationDot,
  faPhone,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {AppButton, AppInput, InputType, Shimmer, Shimmers, Spacer} from "../../components";
import {ViewHeader} from "../../components/ViewHeader";
import {divLeft, DivOrNull, divWidth} from "../../util/div_util";
import {debounce} from "@mui/material";
import Modal from "../../components/Modal";
import useStore from "../../hooks/useStore";
import {GoogleMap, MarkerF} from "@react-google-maps/api";
import {getAnalytics, logEvent} from "firebase/analytics";
import {delay} from "../../util/promise_util";
import {showError} from "../../util/toast_util";
import {capitalize} from "../../util/string_util";
import {getPaid, setPaid} from "../../services/storage";
import useCountry from "../../hooks/useCountry";
import {brands} from "./ProductsView";
import parsePhoneNumber from 'libphonenumber-js'
import {formatDate} from "../../util/date_util";

var valid = require("card-validator");

interface ProductDetailsViewProps {
}

export const ProductDetailsView: React.FC<ProductDetailsViewProps> = (props) => {
  const {country} = useCountry()
  const navigate = useNavigate()
  const {productId} = useParams() as any
  const {product} = useProduct(productId)
  const {store} = useStore(product?.storeId ?? '')
  const container = useRef<DivOrNull>(null)
  const firstImageItem = useRef<DivOrNull>(null)
  const imageUrl = (product?.photos ?? []).length == 0 ? '' : product?.photos?.[0]
  const [selectedImage, setSelectedImage] = useState(0)
  const [scrolling, setScrolling] = useState(false)
  const [showLocationInfo, setShowLocationInfo] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const {city, address, longitude, latitude, phoneNumber} = store ?? {}

  const onScrollDebounced = useCallback(debounce((e?: any) => {
    const topOffset = divLeft(firstImageItem) - divLeft(container)
    const position = Math.floor(Math.abs(topOffset) / divWidth(firstImageItem))
    setSelectedImage(position)
    setScrolling(false)
  }, 100), [])

  const onLocationsScroll = useCallback(() => {
    if (!scrolling) {
      setScrolling(true)
    }
    onScrollDebounced()
  }, [])

  const onLocationsLeftClick = () => {
    container.current?.scrollTo({
      left: (selectedImage - 1) * divWidth(firstImageItem),
      behavior: 'smooth'
    })
  }

  const onLocationsRightClick = () => {
    container.current?.scrollTo({
      left: (selectedImage + 1) * divWidth(firstImageItem),
      behavior: 'smooth'
    })

  }

  const logShowLocation = () => {
    logEvent(getAnalytics(), 'click_show_location');
  }

  const logViewOnMap = () => {
    logEvent(getAnalytics(), 'click_view_on_map');
  }

  const onShowLocationClick = async () => {

    logShowLocation()

    if (!getPaid()) {
      setShowPaymentModal(true)
      return
    }

    setShowLocationInfo(true)
  }

  const priceLocal = useMemo(() => {
    if (country == 'IL') {
      return `₪${product?.price ?? 0}`
    } else {
      return `€${Math.floor((product?.price ?? 0) / 3.5)}`
    }
  }, [country, product])

  const formatPhoneNumber = (number: string) : string => {
    // Format the number as desired, for example: (XXX) XXX-XXXX
    return parsePhoneNumber(number)?.formatInternational() ?? ''
    // return `+(${number.slice(1, 4)}) ${number.slice(4, 7)}-${number.slice(7)}`;
  };

  return <div className={classes.ProductDetailsView}>

    {product && <div className={'mt-2'}>
      <ViewHeader
        title={product?.name ?? ''}
        showBackButton
        onBackClick={() => navigate('/')}
      />

      <div className={classes.LocationCardsContainer}>

        <div className={classes.LocationCardsSlider} ref={container} onScroll={onLocationsScroll}>

          {product?.photos
            ?.map((l, i) => [
              <ImageInfo imageUrl={l ?? ''} ref={i == 0 ? firstImageItem : undefined}/>,
              <Spacer width={20}/>
            ])
            ?.flatMap(e => [e[0], e[1]])}

        </div>

        <div
          className={classes.SlideButtonLeft}
          onClick={onLocationsLeftClick}
          style={{opacity: selectedImage == 0 ? 0 : 1}}>
          <FontAwesomeIcon icon={faChevronLeft}/>
        </div>

        <div
          className={classes.SlideButtonRight}
          onClick={onLocationsRightClick}
          style={{opacity: selectedImage == (product?.photos?.length ?? 0) - 1 ? 0 : 1}}>
          <FontAwesomeIcon icon={faChevronRight}/>
        </div>

      </div>

      {<div className={classes.DotsContainer}>
        {(product?.photos??[]).length > 1 && product?.photos?.map((_, i) => <div
          className={selectedImage == i ? classes.SelectedDot : classes.Dot}/>)}
      </div>}

      {!imageUrl && <div className={classes.Image}>
        <FontAwesomeIcon icon={faImage} className={classes.Icon}/>
      </div>}

      <div className={classes.Info}>

        <div className={classes.Name}>
          {capitalize(brands[product?.brand ?? '']?.toString() ?? '')} {product.name}
        </div>

        {/*<div className={classes.Price}>*/}
        {/*  {capitalize(brands[product?.brand ?? '']?.toString() ?? '')}*/}
        {/*</div>*/}

        {product.createdAt && <div className={classes.ActualDateContainer}>
          <div className={classes.ActualDate}>
          <FontAwesomeIcon icon={faCheck} className={classes.ActualDateIcon}/>
          {`In stock on ${formatDate(product.createdAt ?? '', 'MMMM dd')}`}
        </div>
        </div>}

      </div>

      <Spacer height={20}/>

      <div className={classes.LocationTitle}>
        {store?.name}
      </div>

      <Spacer height={10}/>

      {address && <div className={classes.LocationInfoLine}>
        <div className={classes.IconContainer}>
          <FontAwesomeIcon icon={faLocationDot} className={classes.Icon}/>
        </div>
        <Spacer width={6}/>
        {`${city}, ${address}`}
      </div>}

      <Spacer height={6}/>

      {phoneNumber && <div className={classes.LocationInfoLine}>
        <div className={classes.IconContainer}>
          <FontAwesomeIcon icon={faPhone} className={classes.Icon}/>
        </div>
        <Spacer width={6}/>
        <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
      </div>}

      {/*<div className={classes.MapContainer}>*/}
      {/*  <div className={classes.Map}>*/}
      {/*    {<GoogleMap*/}
      {/*      mapContainerClassName={classes.Map}*/}
      {/*      zoom={16}*/}
      {/*      options={{*/}
      {/*        disableDefaultUI: true,*/}
      {/*      }}*/}
      {/*      center={{*/}
      {/*        lat: latitude ?? 0,*/}
      {/*        lng: longitude ?? 0*/}
      {/*      }}>*/}
      {/*      <MarkerF*/}
      {/*        position={{*/}
      {/*          lat: latitude ?? 0,*/}
      {/*          lng: longitude ?? 0*/}
      {/*        }}>*/}
      {/*        test*/}
      {/*      </MarkerF>*/}
      {/*    </GoogleMap>}*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Spacer height={24}/>

      <a
        href={`https://maps.google.com/?q=${city}, ${address}`}
        target='_blank'
        className={classes.LocationTitle}>
        <AppButton
          title={'Direction'}
          className={classes.LocationButton}
          icon={faMapLocationDot}
          onClick={logViewOnMap}/>
      </a>

      <Spacer height={12}/>

      {phoneNumber && <AppButton
        title={formatPhoneNumber(phoneNumber ?? '')}
        className={classes.LocationButton}
        icon={faPhone}
        onClick={logViewOnMap}/>}



      <Spacer height={20}/>

      {/*<AppButton*/}
      {/*  title={'Show store location'}*/}
      {/*  className={classes.RegisterButton}*/}
      {/*  icon={faMapLocationDot}*/}
      {/*  onClick={onShowLocationClick}/>*/}

      {!!product?.description && <h2 className={classes.ContentTitle}>About sneakers</h2>}

      <div className={classes.Content}>
        {product?.description}
      </div>

    </div>}

    {!product && <Shimmers className={'mt-4 mb-5'}>
      <div className={'w-100'}>
        <ViewHeader loading/>

        <div className={'mt-2'}>
          <div className={classes.Image}>
            <Shimmer expand/>
          </div>
        </div>

        <div className={'d-flex mt-4'}>
          <div className={classes.InfosContainer}>
            <div>
              <Shimmer width={280}/>
            </div>
            <div className={'mt-1'}>
              <Shimmer width={230}/>
            </div>
          </div>

          <Shimmer width={150} height={44}/>

        </div>

        <div className={'mt-5'}>
          <Shimmer width={200}/>
        </div>

        <div className={'mt-3'}>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
          <div className={'mt-1'}><Shimmer expand height={20}/></div>
        </div>

        <div className={'mt-5'}>
          <Shimmer width={200}/>
        </div>

        <div className={'mt-3'}>
          <div className={classes.Image}>
            <Shimmer expand/>
          </div>
        </div>

      </div>
    </Shimmers>}

    {showLocationInfo && <Modal
        title={''}
        onClose={() => setShowLocationInfo(false)}
        opened={showLocationInfo}>

        <div className={classes.LocationTitle}>
          {`${city}, ${address}`}
        </div>

        <div className={classes.MapContainer}>
          <div className={classes.Map}>
            {<GoogleMap
              mapContainerClassName={classes.Map}
              zoom={16}
              options={{
                disableDefaultUI: true,
              }}
              center={{
                lat: latitude ?? 0,
                lng: longitude ?? 0
              }}>
              <MarkerF
                position={{
                  lat: latitude ?? 0,
                  lng: longitude ?? 0
                }}>
                test
              </MarkerF>
            </GoogleMap>}
          </div>
        </div>

        <a
          href={`https://maps.google.com/?q=${city}, ${address}`}
          target='_blank'
          className={classes.LocationTitle}>
          <AppButton
            title={'Open on map'}
            className={classes.LocationButton}
            icon={faMapLocationDot}
            onClick={logViewOnMap}/>
        </a>

      </Modal>}

    <PaymentModal
      opened={showPaymentModal}
      onClose={() => setShowPaymentModal(false)}
      onPaid={async () => {
        await setPaid(true)
        setShowPaymentModal(false)
        setShowLocationInfo(true)
      }}/>

  </div>
}

interface ImageInfoProps {
  imageUrl: string
}

const ImageInfo = forwardRef((props: ImageInfoProps, ref: ForwardedRef<DivOrNull>) => {
  const {imageUrl} = props
  return <div className={classes.LocationCard} ref={ref}>
    <div className={classes.Map}>
      <img src={imageUrl} className={classes.Image}/>
    </div>
  </div>
});

interface PaymentModalProps {
  opened: boolean
  onClose: () => void
  onPaid: () => void

}

const PaymentModal = (props: PaymentModalProps) => {
  const {opened, onClose, onPaid} = props
  const [progress, setProgress] = useState(false)
  const [success, setSuccess] = useState(false)
  const [name, setName] = useState('')
  const [cardNumber, setCardNumber] = useState<string>('')
  const [expiryDate, setExpiryDate] = useState('')
  const [cvv, setCvv] = useState('')
  const [nameError, setNameError] = useState(false)
  const [cardNumberError, setCardNumberError] = useState(false)
  const [expiryDateError, setExpiryDateError] = useState(false)
  const [cvvError, setCvvError] = useState(false)
  const {country} = useCountry()

  useEffect(() => {
    setNameError(false)
  }, [name])

  useEffect(() => {
    setCardNumberError(false)
  }, [cardNumber])

  useEffect(() => {
    setExpiryDateError(false)
  }, [expiryDate])

  useEffect(() => {
    setCvvError(false)
  }, [cvv])

  const price = useMemo(() => {
    if (country == 'IL') {
      return '₪9.99'
    } else {
      return '€2.99'
    }
  }, [country])

  const onPayClick = async () => {

    if (name.length == 0 || name.split(' ').length != 2) {
      showError('Invalid name')
      setNameError(true)
      return
    }

    if (cardNumber.replaceAll(' ', '').length != 16) {
      showError('Invalid card number 1')
      setCardNumberError(true)
      return
    }

    const cardValidator = valid.number(cardNumber.replace(' ', ''))
    console.log(`onPayClick`, cardValidator)

    if (!valid.number(cardNumber.replace(' ', '')).isPotentiallyValid) {
      showError('Invalid card number')
      setCardNumberError(true)
      return
    }

    if (expiryDate.length != 5) {
      showError('Invalid expiry date')
      setExpiryDateError(true)
      return
    }

    if (cvv.length != 3) {
      showError('Invalid cvv')
      setCvvError(true)
      return
    }

    logEvent(getAnalytics(), 'payment_done');

    setProgress(true)
    await delay(2000)
    setSuccess(true)
    await delay(1000)
    setProgress(false)
    setSuccess(false)
    onClose()
    onPaid()
  }

  return <Modal
    title={'👑 Get premium'}
    onClose={onClose}
    opened={opened}>

    <div className={classes.PaymentModal}>

      {success && <div className={classes.SuccessContent}>
        <Spacer height={118}/>
        <FontAwesomeIcon icon={faCheckCircle} className={classes.SuccessIcon}/>
        <Spacer height={10}/>
        <div className={classes.SuccessInfo}>Payment successful</div>
        <Spacer height={140}/>
      </div>}

      {!success && <div className={classes.PaymentContent}>
        <div className={classes.PremiumInfo}>
          <p>Viewing sneaker locations is only available with a subscription.</p>
          <p>Get unlimited views with {price}/mo</p>
        </div>

        <Spacer height={30}/>

        <AppInput
          placeholder={'Cardholder name'}
          onChange={setName}
          error={nameError}
          uppercase/>

        <Spacer height={10}/>

        <AppInput
          placeholder={'Card number'}
          type={InputType.card}
          onChange={(v: any) => setCardNumber(v)}
          error={cardNumberError}
          uppercase/>

        <Spacer height={10}/>

        <div className={classes.CardDateContainer}>

          <div className={'flex-grow-1'}>
            <AppInput
              placeholder={'Expiry date'}
              type={InputType.cardExpiryDate}
              onChange={setExpiryDate}
              error={expiryDateError}
              uppercase
            />
          </div>

          <Spacer width={10}/>

          <div className={'flex-grow-1'}>

            <AppInput
              placeholder={'CVV'}
              type={InputType.number}
              maxLength={3}
              onChange={setCvv}
              error={cvvError}
              uppercase/>
          </div>

        </div>

        <Spacer height={10}/>

        <AppButton
          title={`Pay ${price}`}
          className={classes.PayButton}
          icon={faWallet}
          onClick={onPayClick}
          progress={progress}/>

      </div>}

    </div>

  </Modal>
}

