import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import classes from './Main.module.scss'
import Header from "./header/Header";
import ProductsView from "./ProductsView";
import Footer from "./Footer";
import { isBrowser, isDesktop, isMobile } from 'react-device-detect';
import { BottomNavigationBar } from "./BottomNavigationBar";
import { useIntercom } from "react-use-intercom";
import {ProductDetailsView} from "./ProductDetailsView";
import {ImageCutter} from "../ recognizer/ImageCutter";

export default function Main() {

  const navigate = useNavigate()
  const {pathname, search} = useLocation()

  return <div className={classes.MainWrapper}>

    <div className={classes.Main}>

      {/*{isBrowser && <Header/>}*/}

      <div className={classes.MainContent}>
        <Routes>
          <Route path='/recognizer/*' element={<ImageCutter/>}/>
          <Route path='/product/:productId/*' element={<ProductDetailsView/>}/>
          <Route path='/' element={<ProductsView/>}/>
          <Route path='/*' element={<Navigate to={'/'}/>}/>
        </Routes>
      </div>

      {isBrowser && <Footer/>}

    </div>

    {/*{isMobile && <Routes>
      <Route path='/event' element={<BottomNavigationBar/>}/>
      <Route path='/wishlist' element={<BottomNavigationBar/>}/>
      <Route path='/wristbands' element={<BottomNavigationBar/>}/>
      <Route path='/account' element={<BottomNavigationBar/>}/>
      <Route path='/support' element={<BottomNavigationBar/>}/>
      <Route path='/' element={<BottomNavigationBar/>}/>
    </Routes>}*/}

  </div>
}
