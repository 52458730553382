import React, {PropsWithChildren} from 'react'
import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import SpinnerSmall from "./SpinnerSmall";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface SmallButtonProps {
  title: string
  onClick: VoidFunction
  danger?: boolean
  icon?: IconProp
  className?: string
  progress?: boolean
}

export const SmallButton: React.FC<SmallButtonProps & PropsWithChildren<any>> = (props) => {
  const {title, onClick, danger, icon, className, children, progress} = props
  return <Button
    onClick={onClick}
    className={classnames(className, 'action-button', {'danger-button': danger})}>

    {progress && <SpinnerSmall/>}

    {!progress && icon && <div style={{paddingTop: 1, marginRight: 10}}>
      <FontAwesomeIcon icon={icon} className='action-button-icon'/>
    </div>}

    {!progress && title}

    {!progress && children}

  </Button>
}
