import {getStore, updateStore} from '../services/api/api'
import useSWR from 'swr'
import useSWRMutation from "swr/mutation";
import {mutate} from "swr/_internal";
import {Store} from "../models";

export default function useStore(storeId: string) {
  const {data, error, isLoading} = useSWR(['getStore', storeId], getStore)
  return {
    store: data,
    storeLoading: isLoading,
    storeError: error,
  }
}

export const useUpdateStore = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, Store>('updateStore', updateStore)
  return {
    updateStore: trigger,
    updatingStore: isMutating,
    updatingStoreError: error
  }
}

export const refreshStore = async (storeId: string, store?: Store) => {
  await mutate(['getStore', storeId], store)
}
