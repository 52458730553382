import React, {useEffect, useMemo, useState} from 'react'
import classes from './ProductsView.module.scss'
import {useProducts} from "../../hooks/useProducts";
import {showError} from "../../util/toast_util";
import {ProductItem} from "../../components/ProductItem";
import PlaceholderView from "../../components/PlaceholderView";
import {ReactComponent as Logo} from '../../images/logo.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faChevronDown, faSearch, faWallet} from "@fortawesome/free-solid-svg-icons";
import {AppButton, AppInput, InputType, ModalProps, Spacer} from "../../components";
import {SearchBox} from "../../components/SearchBox";
import {useSearchParams} from "react-router-dom";
import {paramsToObject, setValue} from "../../util/search_params_util";
import {formatDate, sameDay} from "../../util/date_util";
import useCountry from "../../hooks/useCountry";
import Modal from "../../components/Modal";
import valid from "card-validator";
import {getAnalytics, logEvent} from "firebase/analytics";
import {delay} from "../../util/promise_util";
import {capitalize} from "../../util/string_util";

export const brands : Record<string, string> = {
  'nike': 'Nike',
  'adidas': 'Adidas',
  'puma': 'Puma',
  'new_balance': 'New Balance',
  'reebok': 'Reebok',
  'converse': 'Converse',
  'vans': 'VANS',
  'under_armour': 'Under Armour',
  'skechers': 'Skechers',
  'fila': 'FILA',
  'jordan': 'Jordan',
  'asics': 'ASICS',
  'brooks': 'Brooks',
  'salomon': 'Salomon',
  'lacoste': 'Lacoste',
  'timberland': 'Timberland',
  'hoka_one_one': 'HOKA One One',
  'merrell': 'Merrell',
  'k_swiss': 'K-Swiss',
  'onitsuka_tiger': 'Onitsuka Tiger',
  'dc_shoes': 'DC Shoes',
  'supra': 'Supra',
  'etnies': 'Etnies',
  'the_north_face': 'The North Face',
  'diadora': 'Diadora',
  'mizuno': 'Mizuno',
  'saucony': 'Saucony',
  'sperry': 'Sperry',
  'ugg': 'UGG',
  'ea7': 'EA7'
}

type Brand = {
  brand: string
  brandName: string
}

export default function ProductsView() {

  const paramsFromParams = () => {
    return {
      search: search,
      dateFrom: !dateFrom ? undefined : dateFrom,
      dateTo: !dateTo ? undefined : dateTo
    }
  }
  const [params, setParams] = useSearchParams()
  const {dateFrom, dateTo, search} = paramsToObject(params) as {
    dateFrom?: string,
    dateTo?: string,
    search?: string
  }
  const {country} = useCountry()
  const [openSelectBrandModal, setOpenSelectBrandModal] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState<Brand>()

  console.log(`ProductsView - selectedBrand: `, selectedBrand)

  const {
    products,
    productsError,
    productsLoading
  } = useProducts(paramsFromParams())

  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    if (productsError && productsError.length) {
      showError(productsError)
    }
  }, [productsError])

  useEffect(() => {
    if ((search && search.length) || dateFrom || dateTo) {
      setShowSearchBar(true)
    }
  }, [dateFrom, dateTo, search])

  // todo fix it
  const onDatesChanged = (from: Date | undefined, to: Date | undefined) => {
    console.log(`onDatesChanged - ${from} - ${to}`)

    const fromDifferent = (!from && dateFrom) || (from && !dateFrom) || (from && dateFrom && !sameDay(from, new Date(dateFrom)))
    const toDifferent = (!to && dateTo) || (to && !dateTo) || (to && dateTo && !sameDay(to, new Date(dateTo)))

    if (fromDifferent || toDifferent) {
      let newParams = new URLSearchParams(params.toString());
      newParams = setValue(newParams, 'dateTo', formatDate(to))
      newParams = setValue(newParams, 'dateFrom', formatDate(from))
      setParams(newParams)
    }
  }

  const onSearchChange = (mySearch: string) => {
    if (mySearch != search) {
      let newParams = new URLSearchParams(params.toString());
      newParams = setValue(newParams, 'search', mySearch)
      setParams(newParams)
    }
  }

  const onSearchClose = () => {
    setShowSearchBar(false)
    setParams(new URLSearchParams())
  }

  const controls = () => {
    return <div className={classes.Controls}>

      <div className={classes.TopContent}>
        {showSearchBar && <div className={classes.SearchBoxWrapper}>
          <SearchBox
            initialValue={search}
            onSearchChange={onSearchChange}
            onCloseClick={onSearchClose}
            placeholder={'Search by name or description'}/>
        </div>}

        {!showSearchBar && <>
          <Logo className={classes.Logo}/>
          <Spacer/>
          <div className={classes.IconButton} onClick={() => setShowSearchBar(!showSearchBar)}>
            <FontAwesomeIcon icon={faSearch}/>
          </div>
        </>}

        {/*<div className={classes.IconButton} onClick={() => setShowFilters(!showFilters)}>
          <FontAwesomeIcon icon={faFilter}/>
        </div>*/}
      </div>

      {/*{showSearchBar && <div className={classes.Filters}>
        <DateControl
          dateFrom={dateFrom ? new Date(dateFrom) : undefined}
          dateTo={dateTo ? new Date(dateTo) : undefined}
          onDatesChanged={onDatesChanged}/>
      </div>}*/}

      {!showSearchBar && <Spacer height={16}/>}

    </div>
  }

  const city = useMemo(() => {
    if (country == 'IL') {
      return 'Haifa'
    }
    if (country == 'CY') {
      return 'Pafos'
    }
    return 'your city'
  }, [country])

  const filteredProducts = useMemo(() => {
    return (selectedBrand ? products?.filter((p) => p.brand == selectedBrand.brand) : products) ?? []
  }, [selectedBrand, products])

  console.log(`ProductsView - filtered: `, filteredProducts.map((p) => p.brand))


  return <div className={classes.EventsView}>

    {/*{isMobile && controls()}

    {isDesktop && <div className={'pb-3'}>
      <SearchBox
        initialValue={search}
        onSearchChange={onSearchChange}
        placeholder={'Search by name or description'}/>
    </div>}*/}

    <div className={classes.MainTitle}>
      <div>Sneakers in {city} right now</div>
    </div>

    <div className={classes.BrandSelector} onClick={() => setOpenSelectBrandModal(true)}>
      {selectedBrand?.brandName ?? 'Select brand'}
      <Spacer/>
      <FontAwesomeIcon icon={faChevronDown}/>
    </div>

    <Spacer height={10}/>

    {filteredProducts && !filteredProducts.length && !productsLoading &&
      <PlaceholderView title={'There are no products'}/>}

    {!filteredProducts && productsLoading && <div className={classes.EventsGrid}>
      {[...Array(6)].map((i) => <ProductItem shimmer key={i}/>)}
    </div>}

    {filteredProducts && !!filteredProducts.length && <div className={classes.EventsGrid}>
      {filteredProducts?.map((e, i) => <ProductItem
        key={e.id}
        product={e}
        index={i + 1}
      />)}
    </div>}

    {openSelectBrandModal && <SelectBrandModal
      opened={openSelectBrandModal}
      onClose={() => setOpenSelectBrandModal(false)}
      selectedBrand={selectedBrand}
      onSelect={setSelectedBrand}
    />}

  </div>
}

interface SelectBrandModalProps {
  selectedBrand?: Brand
  onSelect: (brand: Brand) => void
}

const SelectBrandModal = (props: SelectBrandModalProps & ModalProps) => {
  const {selectedBrand, onSelect} = props
  const [sortedBrands, setSortedBrands] = useState<Brand[]>([])
  const {products} = useProducts()

  useEffect(() => {
    const sorted = Object.entries(brands).sort((a, b) => a[1].localeCompare(b[1])).map((e) => ({
      brand: e[0],
      brandName: e[1]
    }))
    setSortedBrands(sorted)
  }, [])

  const item = (brandObject: any) => {
    // console.log(`item - brandObject: `, brandObject)
    const {brand, brandName} = brandObject
    const selected = selectedBrand?.brand == brand
    console.log(`item - brand: ${brand}, brandName: ${brandName}, selected: ${selected}`)
    return <div className={selected ? classes.ItemSelected : classes.Item} onClick={() => onSelectBrand(brandObject)}>
      {capitalize(brandName)}
      <Spacer/>
      {selected ? <FontAwesomeIcon icon={faCheckCircle} className={classes.CheckIcon}/> : null}
    </div>
  }

  const onSelectBrand = (brand: Brand) => {
    onSelect(brand)
    props.onClose()
  }

  const usedBrands : Brand[] = useMemo(() => {

    // todo return only brands that are in the products
    const brandsSet = new Set(products?.map((p) => p.brand))
    return sortedBrands.filter((b) => brandsSet.has(b.brand))

  },[sortedBrands, products])

  return <Modal
    {...props}
    title={'Select brand'}>

    <div className={classes.SelectBrandModal}>

      {usedBrands.map(item)}

    </div>

  </Modal>
}


