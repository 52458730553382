export const paramsToObject = (params: URLSearchParams) => {
  const result: Record<string, string> = {}
  for (const [key, value] of params.entries()) {
    if (value != undefined) {
      result[key] = value
    }
  }
  return result
}

export const setValue = (params: URLSearchParams, key: string, value: string | undefined): URLSearchParams => {
  const newParams = new URLSearchParams(params.toString());
  if (!value) {
    newParams.delete(key)
  } else {
    newParams.set(key, value)
  }
  return newParams
}
