import useSWR from 'swr'
import {getCountry} from "../services/api/ip_api";

export default function useCountry() {
  const {data, error, isLoading} = useSWR('getCountry', getCountry)
  return {
    country: data,
    countryLoading: isLoading,
    countryError: error,
  }
}
