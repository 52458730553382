import { getProduct, updateProduct } from '../services/api/api'
import useSWR from 'swr'
import useSWRMutation from "swr/mutation";
import { mutate } from "swr/_internal";
import { Event } from "../services/api/models";
import {Product} from "../models";

export default function useProduct(productId: string) {
  const {data, error, isLoading} = useSWR(['getProduct', productId], getProduct)
  return {
    product: data,
    productLoading: isLoading,
    productError: error,
  }
}


export const refreshProduct = async (productId: string, product?: Product) => {
  await mutate(['getProduct', productId], product)
}
