import React from 'react'
import classes from './Footer.module.scss'
import { Spacer } from "../../components";
import { Link } from "react-router-dom";
import { version } from "../../config/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { isBrowser, isMobile } from 'react-device-detect';

export default function Footer() {
  return <div className={classes.Footer} style={{height: isMobile ? 'auto' : undefined}}>

    {isMobile && <div className={classes.FooterContentMobile}>

      <a href={'https://peepapp.org/privacy-policy'}>
        <div> Privacy Policy</div>
      </a>

      <Spacer height={10}/>

      {/*<Link to={'/support'}>
        <div className={classes.FooterContent}>
          <FontAwesomeIcon icon={faCommentDots}/>
          <Spacer width={4}/>
          <div>Support</div>
        </div>
      </Link>

      <Spacer height={10}/>*/}

      <a href={'https://peepapp.org'}>
        <div>© 2023 Peep app</div>
      </a>
      <Spacer height={10}/>

      <div>v{version}</div>

    </div>}

    {isBrowser && <div className={classes.FooterContent}>
      <a href={'https://sneaker-x.com'}>
        <div>© 2023 Sneaker X</div>
      </a>
      <Spacer/>
      <div>v{version}-{process.env.REACT_APP_ENV}</div>
      <div className={classes.FooterDivider}>|</div>
      {/*<Link to={'/support'}>
        <div className={classes.FooterContent}>
          <FontAwesomeIcon icon={faCommentDots}/>
          <Spacer width={4}/>
          <div>Support</div>
        </div>
      </Link>
      <div className={classes.FooterDivider}>|</div>*/}
      <a href={'https://peepapp.org/privacy-policy'}>
        <div> Privacy Policy</div>
      </a>
    </div>}

  </div>
}
