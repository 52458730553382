export const elementsEqual = (array: any[]): boolean => {
  return !array.length || array.every(e => e === array[0])
}

export const last = <T>(array?: T[]): T | undefined => {
  return (!array || array.length == 0) ? undefined : array[array.length - 1]
}

export const removeElementAt = <T>(array: T[], index: number): T[] => {

  if (index < 0) {
    return array
  }

  if (array.length == 1) {
    return []
  }

  const result = [...array]
  result.splice(index, 1)
  return result

}

export const addOrReplaceAtIndex = <T>(array: T[], index: number, element: T): T[] => {

  if (index < array.length) {
    const result = [...array]
    result[index] = element
    return result
  }

  return [
    ...array,
    element
  ]

}

export const isEmpty = (array?: any[] | null): boolean => {
  return !array || !array.length
}

export const isNotEmpty = (array?: any[] | null): boolean => {
  return !isEmpty(array)
}
