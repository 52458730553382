import React from 'react'
import classes from './PlaceholderView.module.scss'
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

interface PlaceholderViewProps {
  title: string
  icon?: IconDefinition
}

export default function PlaceholderView(props: PlaceholderViewProps) {
  const {title, icon} = props

  return <div className={classes.PlaceholderView}>

    <FontAwesomeIcon icon={icon ?? faTriangleExclamation} className={classes.Icon}/>

    <div className={classes.Title}>
      {title}
    </div>

  </div>
}
