import React from "react"
import classes from "./Spinner.module.scss"
import { Spinner as Spin } from "react-bootstrap";
import classnames from "classnames";

interface SpinnerProps {
  accent?: boolean
  size?: number
  secondary?: boolean
  color?: string
  opacity?: number
}

export default function Spinner(props: SpinnerProps) {
  const {accent, size, secondary, color, opacity} = props
  return <Spin
    className={classnames(classes.SpinnerBorder, {[classes.Accent]: accent, [classes.Secondary]: secondary})}
    style={{width: size, height: size, color: color, opacity: opacity}}
    role="status"
    animation="border"/>
}
