import {GraphQLClient} from 'graphql-request'
import JSON5 from 'json5'
import {stringify} from "../../util/object_util";
import {capitalize} from "../../util/string_util";
import {Product, Store} from "../../models";
import {S3Client, PutObjectCommand} from "@aws-sdk/client-s3";

const API_URL = process.env.REACT_APP_GRAPHQL_API_HOST ?? ''
const API_KEY = process.env.REACT_APP_GRAPHQL_API_KEY ?? ''

const graphQLClient = new GraphQLClient(
  `${API_URL}/graphql`,
  {
    headers: {
      'x-api-key': API_KEY ?? ''
    },
  }
)

type EqFilter = {
  field: string
  value: string
}

export interface GetProductsParams {
  priceFrom?: string
  priceTo?: string
  search?: string
  nextToken?: string
}

export interface ProductsPageData {
  products: Product[]
  nextToken?: string
}

export const getProduct = async (params: string[]): Promise<Product> => {
  const [, productId] = params
  const {getProduct} = await request(`
    query MyQuery {
      getProduct(id: "${productId}") {
        id
        name
        price
        storeId
        description
        photos
        brand
        createdAt
        store {
          id
          name
          address
          city
          country
          phoneNumber
        }
      }
    }
  `)

  console.log(`getProduct - ${JSON.stringify(getProduct)}`)
  return {...getProduct, store: getProduct.store}
}

export const getProducts = async (params: GetProductsParams[]): Promise<ProductsPageData> => {

  try {

    const [, getParams] = params

    const {
      priceFrom,
      priceTo,
      search,
      nextToken
    } = getParams

    const nextTokenString = !nextToken ? '' : `, nextToken: "${nextToken}"`

    const query = search ?? ''
    const filterObject = {
      price: {
        ge: priceFrom ?? 0,
        le: priceTo ?? 99999999,
      },
      or: [
        {name: {contains: query}},
        {name: {contains: query.toLowerCase()}},
        {name: {contains: query.toUpperCase()}},
        {name: {contains: capitalize(query)}},
      ],
    }


    const result = await request(`
      query MyQuery {
        listProducts (
          filter: ${stringify(filterObject)},
          limit: 10000,
          ${nextTokenString}
        ) {
          items {
            id
            name
            price
            brand
            createdAt
            store {
              id
              name
            }
            storeId
            description
            photos
          }
          nextToken
        }
      }
    `);

    const newProducts: Product[] = result.listProducts.items.map((i : any) => ({
      ...i,
      createdAt: i.createdAt ?? new Date()
    }))

    return {
      products: newProducts,
      nextToken: result.listProducts.nextToken
    }

  } catch (e: any) {
    console.log(`getProducts - error: ${e?.stack}`)
    return {
      products: [],
    }
  }
}

export const getSameProductInStore = async (storeId: string, productName: string): Promise<Product | undefined> => {
  const response = await request(`
    query MyQuery {
      listProducts(filter: {name: {eq: "${productName}"}, storeId: {eq: "${storeId}"}}) {
        items {
          id
          brand
          name
          storeId
        }
      }
    }
  `)

  const items = response.listProducts.items
  return items.length == 0 ? undefined : items[0]
}

export const createProduct = async (product: Product): Promise<Product | undefined> => {
  return create('createProduct', product, ['id'])
}

export const updateProduct = async (product: Product): Promise<Product> => {
  return await update<Product>(
    'updateProduct',
    product.id ?? '',
    product,
    ['id']
  )
}

export const getStore = async (params: string[]): Promise<Store> => {
  const [, storeId] = params
  const store = await get<Store>(
    'getStore',
    storeId,
    [
      'id',
      'name',
      'latitude',
      'longitude',
      'address',
      'city',
      'country',
      'phoneNumber',
      'createdAt',
      'updatedAt'
    ]
  )

  return store
}

export const getStores = async (): Promise<Store[]> => {
  const stores = await request(`
    query MyQuery {
      listStores {
        items {
          id
          address
          city
          country
          createdAt
          facebookUrl
          id
          latitude
          longitude
          name
          phoneNumber
          updatedAt
          phoneNumber
        }
      }
    }
  `)

  console.log(`getStores - stores: `, stores)

  return stores.listStores.items
}

export const createStore = async (url: string, data: any): Promise<Store | undefined> => {
  const store = data.arg
  return create('createStore', store, ['id'])
}

export const updateStore = async (url: string, data: any): Promise<Store> => {
  const store: Store = data.arg
  return await update<Store>(
    'updateStore',
    store.id ?? '',
    store,
    [
      'id',
      'name',
      'latitude',
      'longitude',
      'address',
      'city',
      'country',
      'createdAt',
      'updatedAt'
    ]
  )
}

export const uploadFile = async (file: File): Promise<string> => {
  console.log(`uploadFile - ${file.name}`)

  try {
    const bucketName = 'shoes-price-photos';
    const region = 'us-east-2';

    const response = await new S3Client({
      region,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY ?? '',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY ?? '',
      }
    }).send(new PutObjectCommand({
      Bucket: bucketName,
      Key: file.name,
      Body: file,
      ACL: 'public-read',

    }));

    // console.log(`uploadFile - response: ${response}`)

    return `https://${bucketName}.s3.${region}.amazonaws.com/${file.name}`;
  } catch (e) {
    console.log(`uploadFile - error: ${e}`)
    throw e
  }


}

export const getSerpApiResponse = async (imageUrl: string): Promise<any> => {
  console.log(`getSerpApiResponse - ${imageUrl}`)

  try {
    const response = await request(`
      query MyQuery {
        serpApiProxy(input: "${imageUrl}")
      }
    `);
    return JSON.parse(response.serpApiProxy)
  } catch (e) {
    console.log(`uploadFile - error: ${e}`)
    throw e
  }
}


//
const request = async (query: string): Promise<any> => {

  try {

    const result = await graphQLClient.request<any>(query)

    const {errors} = result

    if (errors && errors.length > 0) {
      throw errors[0].message;
    }

    return result
  } catch (e) {
    throw e
  }
}

const get = async <T>(queryName: string, id: string, fields?: string[]): Promise<T> => {
  const data = await request(`
    query MyQuery {
      ${queryName}(id: "${id}") {
        ${(fields ?? ['id']).join('\n')}
      }
    }
  `)

  return data[queryName];
}

const getList = async <T>(queryName: string, fields?: string[], eqFilter?: EqFilter, parameter?: EqFilter): Promise<T[]> => {

  let filter = ''
  if (eqFilter) {
    const {field, value} = eqFilter
    switch (typeof value) {
      case 'string':
        filter = `filter: {${field}: {eq: "${value}"}}`
        break
      case 'boolean':
      case 'number':
      default:
        filter = `filter: {${field}: {eq: ${value}}}`
        break
    }
  }

  let nextToken = ''
  let allItems: T[] = []

  // Fetch data in a loop while nextToken is not empty
  do {
    const parameterString = !!parameter ? `${parameter.field}: "${parameter.value}"` : ''
    const nextTokenString = !!nextToken ? `nextToken: "${nextToken}"` : ''
    const filterString = filter || nextTokenString || parameterString
      ? `(${[filter, nextTokenString, parameterString].filter(e => !!e).join(',')})`
      : ''

    const data = await request(`
      query MyQuery {
        ${queryName}${filterString} {
          items {
            ${(fields ?? ['id']).join('\n')}
          }
          nextToken
        }
      }
    `)

    const items = data[queryName]?.items ?? []
    allItems = [
      ...allItems,
      ...items
    ]
    nextToken = data[queryName]?.nextToken
  } while (!!nextToken)

  return allItems

}

const getFirstOrNull = async <T>(queryName: string, fields: string[], eqFilter?: EqFilter): Promise<T | null> => {
  const list = await getList<T>(queryName, fields, eqFilter)
  if (!list || !list.length) {
    return null
  }

  return list[0]
}

const create = async <T>(queryName: string, input: T, fields?: string[]): Promise<T> => {
  console.log(`create`)
  const data = await request(`
    mutation MyMutation {
      ${queryName}(input: ${JSON5.stringify(input, {quote: '"'})}) {
        ${(fields ?? ['id']).join('\n')}
      }
    }
  `)
  return data[queryName] as T;
}

const update = async <T>(queryName: string, modelId: string, input: any, fields?: string[]): Promise<T> => {
  console.log(`update`)
  delete input['createdAt']
  delete input['updatedAt']

  const data = await request(`
    mutation MyMutation {
      ${queryName}(input: ${JSON5.stringify({...input, id: modelId}, {quote: '"'})}) {
        ${(fields ?? ['id']).join('\n')}
      }
    }
  `)
  return data[queryName] as T;
}

const remove = async <T>(queryName: string, modelId: string): Promise<T> => {
  const data = await request(`
    mutation MyMutation {
      ${queryName}(input: {id: "${modelId}"}) {
        id
      }
    }
  `)
  return data[queryName] as T;
}
