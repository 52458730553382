export const dataUrlToFile = (dataURL: string, fileName: string): File => {
  const arr = dataURL.split(',')
  const matchArray = arr[0].match(/:(.*?);/)
  if (!matchArray) {
    throw new Error('Cannot parse data URL')
  }
  const mime = matchArray[1]
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, {type: mime});
}
