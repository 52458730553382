import {
  ProductsPageData,
  getProducts,
  GetProductsParams,
  createProduct,
  updateProduct,
} from '../services/api/api'
import useSWRInfinite from "swr/infinite";
import { last } from "../util/array_util";
import useSWRMutation from "swr/mutation";
import {Product} from "../models";

export const useProducts = (params?: GetProductsParams) => {

  try {
    const getKey = (pageNumber: number, prevData: ProductsPageData | null) => {
      const {products, nextToken} = prevData ?? {}
      if (prevData && !products?.length) {
        return null
      }
      return ['getProducts', {...params, nextToken}]
    }

    const {
      data,
      error,
      isLoading,
      size,
      setSize
    } = useSWRInfinite<ProductsPageData>(getKey, getProducts)

    const isEmpty = data?.[0]?.products.length === 0;
    return {
      products: data?.flatMap(d => d.products).filter(p => p.storeId != '2415aa97-7033-4769-8a8b-cbcd15891207'),
      hasMore: !!last(data)?.nextToken,
      isEmpty,
      productsLoading: isLoading,
      productsError: error,
      productsSize: size,
      setSize
    }
  } catch (e) {
    console.log(`useProducts - e: ${e}`)
    return {
      products: [],
      hasMore: false,
      isEmpty: true,
      productsLoading: false,
      productsError: '',
      productsSize: 0,
      setSize: (index: number) => {
      },
    }
  }
}

export const useAddProduct = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, Product>('createProduct', createProduct)
  return {
    addProduct: trigger,
    addingProduct: isMutating,
    addProductError: error
  }
}

export const useUpdateProduct = () => {
  const {trigger, isMutating, error} = useSWRMutation<any, any, any, Product>('updateProduct', updateProduct)
  return {
    updateProduct: trigger,
    updatingProduct: isMutating,
    updatingProductError: error
  }
}
