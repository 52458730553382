import React from 'react'
import { StaticDatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  components: {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          fontFamily: 'Inter',
        },
        label: {
          fontSize: 16,
          fontWeight: 500,
          fontFamily: 'Inter',
        }
      }
    },
    MuiStaticDatePicker: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "backgroundColor": "#4451CB",
            "fontFamily": "Inter"
          },
        },
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: 'Inter',
          "&.Mui-selected": {
            "backgroundColor": "#4451CB",
            "fontFamily": "Inter"
          },
        },
        today: {
          backgroundColor: '#eceefa',
          border: '0px solid #4451CB !important',
          fontFamily: 'Inter',
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "backgroundColor": "#4451CB",
            "fontFamily": "Inter"
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily: 'Inter',
          color: '#aaaaaa !important',
          fontWeight: 500,
        }
      }
    }
  },
} as any);

interface DatePickerProps {
  date?: Date | null
  onChange: (date: Date | null) => void
  minDate?: Date
  allowFuture?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = ({date, onChange, minDate, allowFuture}) => {
  return <ThemeProvider theme={theme}>
    <StaticDatePicker
      displayStaticWrapperAs='desktop'
      openTo='day'
      value={date}
      onChange={d => {
        onChange(!d ? null : new Date(d))
      }}
      // renderInput={(_) => <div/>}
      maxDate={allowFuture ? undefined : new Date()}
      minDate={minDate}
    />
  </ThemeProvider>
}
